<template>
  <b-card>
    <b-card-title class="mb-1">
      {{ title }}
    </b-card-title>
    <b-card-sub-title class="mb-2">
      {{ description }}
    </b-card-sub-title>

    <vue-apex-charts
      type="donut"
      height="350"
      :options="{...apexChatData.donutChart.chartOptions, labels}"
      :series="series"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    labels: {
      type: Array,
      required: true,
    },
    series: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      default: 'Chiffre d\'affaire prévisionnel',
    },
    description: {
      type: String,
      default: 'Par pays ( HT )',
    },
  },
  data() {
    return {
      apexChatData,
    }
  },
}
</script>
