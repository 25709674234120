<template>
  <b-card
    v-if="data"
    class="card-transaction"
    no-body
  >
    <b-card-header>
      <b-card-title>Transactions</b-card-title>
<!--
      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item href="#">
          Last 28 Days
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Month
        </b-dropdown-item>
        <b-dropdown-item href="#">
          Last Year
        </b-dropdown-item>
      </b-dropdown> -->
    </b-card-header>

    <b-card-body>
      <h5 class="text-primary mb-2"><u>Big Product</u>( {{data.filter(d => d.type === 'BigProduct').map(d => d.payment).reduce((a,b) => a + b, 0).toFixed(0)}}€ / {{(data.filter(d => d.type === 'BigProduct').map(d => Number(d.percent)).reduce((a,b) => a + b, 0)) }}% )</h5>
      <div
        v-for="transaction in data.filter(d => d.type === 'BigProduct')"
        :key="transaction.mode"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="transaction.avatarVariant"
            >
              <feather-icon
                size="18"
                :icon="transaction.avatar"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ transaction.mode }}
            </h6>
            <!-- <small>{{ transaction.types }}</small> -->
            <small>{{ transaction.number ? transaction.number : '0' }} produits / {{ transaction.order ? transaction.order : '0' }} commandes</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :class="transaction.deduction ? 'text-danger':'text-success'"
        >
          {{ transaction.payment ? transaction.payment.toFixed(0) : '0' }}€
          <br>
          <span class="text-primary justify-content-end">{{ ((transaction.percent)) }}%</span>
        </div>
      </div>
      <h5 class="text-primary mb-2"><u>Small Product</u> ( {{ data.filter(d => d.type === 'SmallProduct').map(d => d.payment).reduce((a,b) => a + b, 0).toFixed(0)}}€ / {{((data.filter(d => d.type === 'SmallProduct').map(d => Number(d.percent)).reduce((a,b) => a + b, 0)))}}% )</h5>
      <div
        v-for="transaction in data.filter(d => d.type === 'SmallProduct')"
        :key="transaction.mode"
        class="transaction-item"
      >
        <b-media no-body>
          <b-media-aside>
            <b-avatar
              rounded
              size="42"
              :variant="transaction.avatarVariant"
            >
              <feather-icon
                size="18"
                :icon="transaction.avatar"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body>
            <h6 class="transaction-title">
              {{ transaction.mode }}
            </h6>
            <!-- <small>{{ transaction.types }}</small> -->
            <small>{{ transaction.number ? transaction.number : '0' }} produits / {{ transaction.order ? transaction.order : '0' }} commandes</small>
          </b-media-body>
        </b-media>
        <div
          class="font-weight-bolder"
          :class="transaction.deduction ? 'text-danger':'text-success'"
        >
          {{ transaction.payment ? transaction.payment.toFixed(0) : '0' }}€
          <br>
          <span class="text-primary justify-content-end">{{ ((transaction.percent)) }}%</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    // BDropdown,
    // BDropdownItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      required: true,
    },
  },
}
</script>
