<template>
  <section v-if="dashboard" id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col lg="12">
        <b-row class="match-height mb-2">
          <b-col
            lg="3"
            md="3"
            xs="12"
          >
            <flat-pickr
              v-model="rangeDate"
              placeholder="Choisissez une date"
              class="form-control mr-1"
              :config="{ mode: 'range'}"
            />
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col
            lg="3"
            md="3"
            xs="12"
          >
            <a href="/commande?shop=FR">
              <statistic-card-horizontal
                icon="france.svg"
                :statistic="`${dashboard.totalFR && dashboard.totalFR.total ? (Number(dashboard.totalFR.total) - Number(dashboard.totalFR.total_tax)).toFixed(0) : 0}€`"
                :on-hold="dashboard.totalFROnHold"
                :statistic-title="`CA France`"
              />
            </a>
          </b-col>
          <!-- Bar Chart - Orders -->
          <b-col
            lg="3"
            md="3"
            xs="12"
          >
            <a href="/commande?shop=ES">
              <statistic-card-horizontal
                icon="espagne.svg"
                :statistic="`${dashboard.totalES && dashboard.totalES.total ? (Number(dashboard.totalES.total) - Number(dashboard.totalES.total_tax)).toFixed(0) : 0} €`"
                :on-hold="dashboard.totalESOnHold"
                :statistic-title="`CA Espagne`"
              />
            </a>
          </b-col>
          <b-col
            lg="3"
            md="3"
            xs="12"
          >
            <a href="/commande?shop=DE">
              <statistic-card-horizontal
                icon="allemagne.svg"
                :statistic="`${dashboard.totalDE && dashboard.totalDE.total ? (Number(dashboard.totalDE.total) - Number(dashboard.totalDE.total_tax)).toFixed(0) : 0} €`"
                :on-hold="dashboard.totalDEOnHold"
                :statistic-title="`CA Allemagne`"
              />
            </a>
          </b-col>
          <b-col
            lg="3"
            md="3"
            xs="12"
          >
            <a href="/commande?shop=IT">
              <statistic-card-horizontal
                icon="italie.svg"
                :statistic="`${dashboard.totalIT && dashboard.totalIT.total ? (Number(dashboard.totalIT.total) - Number(dashboard.totalIT.total_tax)).toFixed(0) : 0} €`"
                :on-hold="dashboard.totalITOnHold"
                :statistic-title="`CA Italie`"
              />
            </a>
          </b-col>
          <b-col
            lg="3"
            md="3"
            xs="12"
          >
            <a href="/commande?shop=EN">
              <statistic-card-horizontal
                icon="ireland.png"
                :statistic="`${dashboard.totalEN && dashboard.totalEN.total ? (Number(dashboard.totalEN.total) - Number(dashboard.totalEN.total_tax)).toFixed(0) : 0} €`"
                :on-hold="dashboard.totalENOnHold"
                :statistic-title="`CA Irlande`"
              />
            </a>
          </b-col>
          <b-col
            lg="3"
            md="3"
            xs="12"
          >
            <a href="/commande?shop=PT">
              <statistic-card-horizontal
                icon="portugal.png"
                :statistic="`${dashboard.totalPT && dashboard.totalPT.total ? (Number(dashboard.totalPT.total) - Number(dashboard.totalPT.total_tax)).toFixed(0) : 0} €`"
                :on-hold="dashboard.totalPTOnHold"
                :statistic-title="`CA Portugal`"
              />
            </a>
          </b-col>
          <b-col
            lg="3"
            md="3"
            xs="12"
          >
            <a href="/commande?shop=NL">
              <statistic-card-horizontal
                icon="netherlands.png"
                :statistic="`${dashboard.totalNL && dashboard.totalNL.total ? (Number(dashboard.totalNL.total) - Number(dashboard.totalNL.total_tax)).toFixed(0) : 0} €`"
                :on-hold="dashboard.totalNLOnHold"
                :statistic-title="`CA Pays-Bas`"
              />
            </a>
          </b-col>
          <b-col
            lg="3"
            md="3"
            xs="12"
          >
            <a href="/commande?shop=DK">
              <statistic-card-horizontal
                icon="denmark.png"
                :statistic="`${dashboard.totalDK && dashboard.totalDK.total ? ((Number(dashboard.totalDK.total) - Number(dashboard.totalDK.total_tax)) * 0.13).toFixed(0) : 0} €`"
                :on-hold="dashboard.totalDKOnHold"
                :statistic-title="`CA Danemark`"
              />
            </a>
          </b-col>
          <b-col
            lg="3"
            md="3"
            xs="12"
          >
            <a href="/commande?shop=UK">
              <statistic-card-horizontal
                icon="royaume-uni.png"
                :statistic="`${dashboard.totalUK && dashboard.totalUK.total ? ((Number(dashboard.totalUK.total) - Number(dashboard.totalUK.total_tax)) * 1.17).toFixed(0) : 0} €`"
                :on-hold="dashboard.totalUKOnHold"
                :statistic-title="`CA UK`"
              />
            </a>
          </b-col>
        </b-row>
      </b-col>

      <!-- Revenue Report Card -->
      <!-- <b-col lg="8">
        <ecommerce-revenue-report :data="data.revenue" />
      </b-col> -->
      <!--/ Revenue Report Card -->
    </b-row>
    <b-row class="match-height">
      <b-col
        lg="6"
        md="6"
      >
        <b-row class="match-height">
          <b-col
            xl="6"
            md="6"
          >
            <!-- <a href="/failed">
              <statistic-card-vertical
                color="danger"
                icon="XIcon"
                :statistic="dashboard.failed"
                statistic-title="Commandes echouées"
              />
            </a> -->
            <a href="/commande?shop=BB">
              <statistic-card-horizontal
                icon="b2b.png"
                :statistic="`${dashboard.totalBB && dashboard.totalBB.total ? (Number(dashboard.totalBB.total) - Number(dashboard.totalBB.total_tax)).toFixed(0) : 0} €`"
                :on-hold="dashboard.totalBBOnHold"
                :statistic-title="`CA B2B`"
              />
            </a>
          </b-col>
          <b-col
            xl="6"
            md="6"
          >
            <a href="/commande">
              <statistic-card-horizontal
                icon="paris.png"
                :statistic="`${dashboard.boutique && dashboard.boutique.paris ? (dashboard.boutique.paris).toFixed(0) : 0} €`"
                :on-hold="dashboard.boutique ? dashboard.boutique.parisOnHold : 0"
                :counter="dashboard.boutique ? dashboard.boutique.counterParis : 0"
                :statistic-title="`CA PARIS`"
              />
            </a>
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col
            xl="12"
            md="12"
          >
            <ecommerce-transactions :data="dashboard.transactionData" :total="dashboard.total" />
          </b-col>
        </b-row>
      </b-col>
      <b-col
        lg="6"
        md="6"
      >
        <b-row class="match-height">
          <b-col
            xl="6"
            md="6"
          >
            <a href="/commande">
              <statistic-card-horizontal
                icon="lyon.png"
                :statistic="`${dashboard.boutique && dashboard.boutique.lyon ? (dashboard.boutique.lyon).toFixed(0) : 0} €`"
                :on-hold="dashboard.boutique ? dashboard.boutique.lyonOnHold : 0"
                :counter="dashboard.boutique ? dashboard.boutique.counterLyon : 0"
                :statistic-title="`CA LYON`"
              />
            </a>
          </b-col>
          <b-col
            xl="6"
            md="6"
          >
            <a href="/commande">
              <statistic-card-horizontal
                icon="saucisse.png"
                :statistic="`${dashboard.boutique && dashboard.boutique.toulouse ? (dashboard.boutique.toulouse).toFixed(0) : 0} €`"
                :on-hold="dashboard.boutique ? dashboard.boutique.toulouseOnHold : 0"
                :counter="dashboard.boutique ? dashboard.boutique.counterToulouse : 0"
                :statistic-title="`CA TOULOUSE`"
              />
            </a>
          </b-col>
          <b-col
            xl="6"
            md="6"
          >
            <statistic-card-vertical
              color="info"
              icon="TrendingUpIcon"
              :statistic="`${dashboard.prevision}K €`"
              statistic-title="Prévision (mois en cours)"
            />
          </b-col>
          <b-col
            xl="6"
            md="6"
          >
            <statistic-card-vertical
              color="success"
              icon="DollarSignIcon"
              :statistic="`${dashboard.total ? dashboard.total.toFixed(0) : 0}€`"
              :on-hold="dashboard.totalOnHold ? dashboard.totalOnHold.toFixed(0) : 0"
              statistic-title="CA"
            />
          </b-col>
          <!-- <b-col
            xl="12"
            md="12"
          >
            <ecommerce-statistics :data="dashboard.statisticsItems" />
          </b-col> -->
        </b-row>
        <b-row class="match-height">
          <b-col
            xl="12"
            md="12"
          >
            <apex-donut-chart
              v-if="dashboard.previsionSeries"
              :labels="['France', 'Italie', 'Espagne', 'Allemagne', 'Portugal', 'Pays-bas', 'Irlande']"
              :series="dashboard.previsionSeries"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="6"
        md="6"
      >
        <apex-donut-chart
          v-if="dashboard.sourcesFirstSeries && dashboard.sourcesFirstSeries.length"
          title="Acquisition FR"
          :description="`1st Click (UTM) ${dashboard.sourcesFirstSeries.map(s => Number((s.value / 1000))).reduce((a, b) => a + b, 0).toFixed(1)}K sur ${dashboard.totalFR && dashboard.totalFR.total ? ((Number(dashboard.totalFR.total) - Number(dashboard.totalFR.total_tax)) / 1000).toFixed(1) : 0}K`"
          :labels="dashboard.sourcesFirstSeries.map(s => s._id)"
          :series="dashboard.sourcesFirstSeries.map(s => Number((s.value / 1000).toFixed(1)))"
        />
      </b-col>
      <b-col
        xl="6"
        md="6"
      >
        <apex-donut-chart
          v-if="dashboard.sourcesLastSeries && dashboard.sourcesLastSeries.length"
          title="Acquisition FR"
          :description="`Last Click (UTM) ${dashboard.sourcesLastSeries.map(s => Number((s.value / 1000))).reduce((a, b) => a + b, 0).toFixed(1)}K sur ${dashboard.totalFR && dashboard.totalFR.total ? ((Number(dashboard.totalFR.total) - Number(dashboard.totalFR.total_tax)) / 1000).toFixed(1) : 0}K`"
          :labels="dashboard.sourcesLastSeries.map(s => s._id)"
          :series="dashboard.sourcesLastSeries.map(s => Number((s.value / 1000).toFixed(1)))"
        />
      </b-col>
      <!-- <b-col
        xl="3"
        md="3"
      >
        <statistic-card-vertical
          icon="france"
          type="flag"
          :statistic="dashboard.affiliates && dashboard.affiliates.length && dashboard.affiliates.find(d => d._id === 'FR') && dashboard.affiliates.find(d => d._id === 'FR').total_commission && `${dashboard.affiliates.find(d => d._id === 'FR').total_commission.toFixed(0)}€`"
          :sub-statistic="dashboard.affiliates && dashboard.affiliates.length && dashboard.affiliates.find(d => d._id === 'FR')"
          statistic-title="Affiliation France"
        />
      </b-col>
      <b-col
        xl="3"
        md="3"
      >
        <statistic-card-vertical
          icon="espagne"
          type="flag"
          :statistic="dashboard.affiliates && dashboard.affiliates.length && dashboard.affiliates.find(d => d._id === 'ES') && dashboard.affiliates.find(d => d._id === 'ES').total_commission && `${dashboard.affiliates.find(d => d._id === 'ES').total_commission.toFixed(0)}€`"
          :sub-statistic="dashboard.affiliates && dashboard.affiliates.length && dashboard.affiliates.find(d => d._id === 'ES')"
          statistic-title="Affiliation Espagne"
        />
      </b-col>
      <b-col
        xl="3"
        md="3"
      >
        <statistic-card-vertical
          icon="italie"
          type="flag"
          :statistic="dashboard.affiliates && dashboard.affiliates.length && dashboard.affiliates.find(d => d._id === 'IT') && dashboard.affiliates.find(d => d._id === 'IT').total_commission && `${dashboard.affiliates.find(d => d._id === 'IT').total_commission.toFixed(0)}€`"
          :sub-statistic="dashboard.affiliates && dashboard.affiliates.length && dashboard.affiliates.find(d => d._id === 'IT')"
          statistic-title="Affiliation Italie"
        />
      </b-col>
      <b-col
        xl="3"
        md="3"
      >
        <statistic-card-vertical
          icon="allemagne"
          type="flag"
          :statistic="dashboard.affiliates && dashboard.affiliates.length && dashboard.affiliates.find(d => d._id === 'DE') && dashboard.affiliates.find(d => d._id === 'DE').total_commission && `${dashboard.affiliates.find(d => d._id === 'DE').total_commission.toFixed(0)}€`"
          :sub-statistic="dashboard.affiliates && dashboard.affiliates.length && dashboard.affiliates.find(d => d._id === 'DE')"
          statistic-title="Affiliation Allemand"
        />
      </b-col> -->
    </b-row>
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <apex-line-chart v-if="dashboard.chiffreAnnuel" :data="dashboard.chiffreAnnuel" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import flatPickr from 'vue-flatpickr-component'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import ApexDonutChart from '@core/components/charts/apex-chart/ApexDonutChart.vue'
import ApexLineChart from '@core/components/charts/apex-chart/ApexLineChart.vue'
// import EcommerceMedal from './EcommerceMedal.vue'
// import EcommerceStatistics from './EcommerceStatistics.vue'
// import EcommerceRevenueReport from './EcommerceRevenueReport.vue'
// import EcommerceOrderChart from './EcommerceOrderChart.vue'
// import EcommerceProfitChart from './EcommerceProfitChart.vue'
// import EcommerceEarningsChart from './EcommerceEarningsChart.vue'
// import EcommerceCompanyTable from './EcommerceCompanyTable.vue'
// import EcommerceMeetup from './EcommerceMeetup.vue'
// import EcommerceBrowserStates from './EcommerceBrowserStates.vue'
// import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import EcommerceTransactions from './EcommerceTransactions.vue'

export default {
  components: {
    BRow,
    BCol,
    // EcommerceMedal,
    // EcommerceStatistics,
    // EcommerceRevenueReport,
    // EcommerceOrderChart,
    // EcommerceProfitChart,
    StatisticCardVertical,
    StatisticCardHorizontal,
    flatPickr,
    ApexDonutChart,
    ApexLineChart,
    // EcommerceEarningsChart,
    // EcommerceCompanyTable,
    // EcommerceMeetup,
    // EcommerceBrowserStates,
    // EcommerceGoalOverview,
    EcommerceTransactions,
  },
  data() {
    return {
      data: {},
      dashboard: {},
      rangeDate: null,
    }
  },
  watch: {
    rangeDate: {
      handler: 'getData',
      deep: true,
    },
  },
  async created() {
    // data
    const { dashboard } = await this.$http.get('/admin/dashboard')
    this.dashboard = dashboard
    if (!localStorage.getItem('accessToken')) {
      this.$router.push('/login')
    }
    const route = this.$router.options.routes.find(r => window.location.pathname === (r?.path))
    if (localStorage.getItem('type') === 'logistic' && route?.meta?.access !== 'logistic' && route.path !== '/logistic') {
      window.location = '/logistic'
    }
    if (localStorage.getItem('type') === 'sales' && route?.meta?.access !== 'sales' && !['/encaissement', '/counter'].includes(route.path)) {
      window.location = '/encaissement'
    }
    if (localStorage.getItem('type') === 'external' && route?.meta?.access !== 'full' && route.path !== '/') {
      window.location = '/'
    }
    // console.log(test)
    // this.$http.get('/ecommerce/data')
    //   .then(response => {
    //     this.data = response.data
    //     // ? Your API will return name of logged in user or you might just directly get name of logged in user
    //     // ? This is just for demo purpose
    //     const userData = getUserData()
    //     this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
    //   })
  },
  methods: {
    async getData() {
      try {
        const filter = {}
        if (this.rangeDate) {
          const dates = this.rangeDate.includes('to') ? this.rangeDate.split(' to ') : [this.rangeDate, this.rangeDate]
          filter.created_at = { $gte: this.$moment(dates[0]).startOf('days').toDate().getTime(), $lte: this.$moment(dates[1]).endOf('days').toDate().getTime() }
          const { dashboard } = await this.$http.get('/admin/dashboard', { params: { filter } })
          this.dashboard = dashboard
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
