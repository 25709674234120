<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Chiffre d'affaire
        </b-card-title>
        <b-card-sub-title>Par mois (HT)</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- badge -->
      <!-- <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
        <h5 class="font-weight-bolder mb-0 mr-1">
          $ 100,000
        </h5>
        <b-badge variant="light-secondary">
          <feather-icon
            icon="ArrowDownIcon"
            size="16"
            class="text-danger mr-25"
          />
          <span class="align-middle">20%</span>
        </b-badge>
      </div> -->
      <!--/ badge -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        v-if="isAlready && series && apexChatData"
        type="line"
        height="400"
        :options="apexChatData"
        :series="series"
        :labels="series[0].labels"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import apexChatData from './apexChartData'

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    // BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      apexChatData,
      series: [],
      isAlready: false,
    }
  },
  mounted() {
    this.series = [
      {
        name: 'Chiffre d\'affaire',
        data: this.data.map(s => s.value),
      },
    ]
    this.apexChatData = { ...this.apexChatData.lineChartSimple.chartOptions, xaxis: { categories: [...this.data.map(s => s.date)] } }
    this.isAlready = true
  },
}
</script>
