<template>
  <b-card no-body>
    <b-card-body class="d-flex justify-content-between align-items-center">
      <div class="truncate">
        <h2 class="mb-25 font-weight-bolder">
          {{ statistic }}
        </h2>
        <span>{{ statisticTitle }}</span>
      </div>
      <div>
        <b-avatar
          :variant="`light-${color}`"
          size="45"
        >
          <img
            style="width: 30px;"
            :src="require(`@/assets/images/flags/${icon}`)"
          >
        </b-avatar>
        <feather-icon
          size="21"
          icon="EyeIcon"
          class="ml-2"
        />
      </div>
    </b-card-body>
    <h6 v-if="counter" class="transaction-title px-2 mb-1">+ {{ onHold ? (onHold).toFixed(0) : 0 }}€ en attente / {{ counter || 0 }} client(s)</h6>
    <h6 v-else class="transaction-title px-2 mb-1">+ {{ onHold ? (onHold.total - onHold.total_tax).toFixed(0) : 0 }}€ en attente</h6>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar } from 'bootstrap-vue'
import FeatherIcon from '../feather-icon/FeatherIcon.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
    FeatherIcon,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    onHold: {
      type: Object,
      default: null,
    },
    counter: {
      type: Number,
      default: null,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
