<template>
  <b-card class="text-center">
    <b-avatar
      class="mb-1"
      :variant="`light-${color}`"
      size="45"
    >
      <img
        v-if="type === 'flag'"
        style="width: 30px;"
        :src="require(`@/assets/images/flags/${icon}.svg`)"
      >
      <feather-icon
        v-else
        size="21"
        :icon="icon"
      />
    </b-avatar>
    <div class="truncate">
      <h2 class="mb-25 font-weight-bolder">
        {{ statistic }}
      </h2>
      <span>{{ statisticTitle }}</span>
    </div>
    <h6 v-if="onHold" class="transaction-title px-2 mb-1">+ {{ onHold }}€ en attente</h6>
    <span v-if="subStatistic"><strong>+{{ subStatistic.total.toFixed(0) }}€ CA</strong></span>
  </b-card>
</template>

<script>
import { BCard, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'icon',
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    subStatistic: {
      type: Object,
      default: null,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    statisticSubTitle: {
      type: String,
      default: '',
    },
    onHold: {
      type: [String, Number],
      default: null,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
